.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #1a73e8;
    border-radius: 4px;
    width: 500px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #1a73e8
}

.label {
    color: #1a73e8;
    display: flex;
    width: 100%;
    margin-left: 40px;
}

.row {
    display: flex;
    width: 100%;
    margin-left: 40px;
}

.row > *:last-child {
    margin: auto;
}